import React from "react";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Hero from "common/hero";
import Seo from "common/seo";
import CategoryDetails from "common/category-details";
import { TypeA, TypeC } from "common/callouts";

import heroImageRef from "images/baby/eczema/eczema-hero.webp";
import productInfoIcon from "images/icons/icon-eczema-baby.png";
import productInfoImg from "images/baby/eczema/eczema_data-visual.webp";
import img1 from "images/baby/eczema/condition-cream-body-wash.webp";
import img2 from "images/baby/eczema/condition-eczem-relief-cream.webp";
import img3 from "images/baby/eczema/section-itch-features.webp";
import img4 from "images/callouts/cout-footer-aquaphor-baby.webp";
import img5 from "images/callouts/cout-diaper-rash-footer.webp";
import img6 from "images/baby/general-care/Baby-Resources-Callout_Footer.webp";

import "./eczema.scss";

const MyHero = () => <Hero className="inner-hero" bgImagePath={heroImageRef} />;

function EczemaPage({ pageContext }) {
  return (
    <Layout
      pageClass="page-baby-eczema"
      breadcrumbData={pageContext.breadcrumb}
      hero={<MyHero />}
    >
      <Seo
        pageTitle="Baby Eczema"
        pageDescription="Learn about Eucerin and Aquaphor therapeutic care for babies' eczema-prone skin"
      />
      <div className="inner-body-content">
        <section className="inner-centered-container">
          <CategoryDetails
            headBgColor="#A70531"
            headText="ECZEMA"
            infoText="Bring relief with an easy eczema routine for baby"
            bodySpanText=""
            bodyText="Help parents and patients <span class='font-semi-bold'>overcome life's little challenges</span>."
            productInfoIcon={productInfoIcon}
            productInfoIconAlt="Mom and infant palms icon"
            productInfoImg={productInfoImg}
            productInfoImgAlt="Eucerin® Baby Eczema Relief products"
          >
            <h5 className="shiraz-red">
              Recommend Eucerin<sup>&reg;</sup> Baby to calm and soothe eczema
              flares
            </h5>
            <p>
              These specialized skin care products for babies’ eczema-prone skin
              are designed to help relieve the itch and irritations associated
              with eczema.
            </p>
            <ul className="red-bullets">
              <li>
                <span>
                  <span className="font-semi-bold">
                    Clinically demonstrated wash
                  </span>{" "}
                  gently soothes and reduces itch<sup>1</sup>
                </span>
              </li>
              <li>
                <span>
                  <span className="font-semi-bold">
                    {" "}
                    Clinically proven, steroid-free cream
                  </span>{" "}
                  helps prevent future flares<sup>2</sup>
                </span>
              </li>
              <li>
                <span>
                  Daily use of both products helps break the itch-scratch cycle
                  of eczema
                </span>
              </li>
            </ul>
          </CategoryDetails>
        </section>
        <section className="callouts-container gradient-bg gray-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={8} lg={4} order={{ xs: 2, lg: 1 }}>
                <TypeC
                  head1={`Eucerin<sup>&reg;</sup> Baby Eczema Relief Cream & Body Wash`}
                  head1Css="shiraz-red"
                  buttonText="See product information"
                  buttonUrl="/baby/eczema/eucerinbabyeczemareliefbodywash"
                  className="center-v"
                >
                  Gently washes and soothes babies' eczema&#8209;prone skin
                </TypeC>
              </Col>
              <Col sm={12} md={4} lg={2} order={{ xs: 1, lg: 2 }}>
                <img
                  src={img1}
                  alt="Eucerin® Baby Eczema Relief Cream &amp; Body Wash"
                  className="cout-product-img"
                />
              </Col>
              <Col sm={12} md={8} lg={4} order={{ xs: 4, lg: 3 }}>
                <TypeC
                  head1={`Eucerin<sup>&reg;</sup> Baby Eczema Relief Cream`}
                  head1Css="shiraz-red"
                  buttonText="See product information"
                  buttonUrl="/baby/eczema/eucerinbabyeczemareliefcream"
                  className="center-v"
                >
                  Helps relieve itching, minor irritation, and dryness due to
                  eczema
                </TypeC>
              </Col>
              <Col sm={12} md={4} lg={2} order={{ xs: 3, lg: 4 }}>
                <img
                  src={img2}
                  alt="Eucerin® Baby Eczema Relief Cream"
                  className="cout-product-img"
                />
              </Col>
            </Row>
          </div>
          <div className="inner-centered-container">
            <p className="footnotes">
              <span className="font-bold">References: 1.</span> Data on file.
              Beiersdorf Inc. <span className="font-bold">2.</span> Weber TM,
              Samarin F, Babcock MJ, Filbry A, Rippke F. Steroid-free
              over-the-counter eczema skin care formulations{" "}
              <br className="show-desktop" /> reduce risk of flare, prolong time
              to flare, and reduce eczema symptoms in pediatric subjects with
              atopic dermatitis.{" "}
              <span className="font-italic">J Drugs Dermatol</span>.
              2015;14(5):478-485.
            </p>
          </div>
        </section>
        <section className="callouts-container gradient-bg section-eczema-additionalreflief">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={12} lg={6} order={{ xs: 2, lg: 1 }} className="">
                <TypeC
                  prodLabel={`FOR ADDITIONAL RELIEF`}
                  prodLabelCss="h3-boxed picton-blue"
                  buttonText="SEE AQUAPHOR<sup>®</sup> CHILDREN'S ITCH RELIEF OINTMENT"
                  buttonUrl="/baby/generalcare/aquaphorchildrensitchreliefointment"
                  className="center-v"
                >
                  <p className="reg-text-big reg-text-big-only">
                    Help soothe itch for up to <br className="show-desktop" />{" "}
                    12 hours
                  </p>
                </TypeC>
              </Col>
              <Col sm={12} md={12} lg={6} order={{ xs: 1, lg: 2 }} className="">
                <img
                  src={img3}
                  alt="AQUAPHOR® CHILDREN'S ITCH RELIEF OINTMENT"
                  className="cout-product-img"
                />
              </Col>
            </Row>
          </div>
        </section>
        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img4}
                  imgAlt="Aquaphor® Baby skin care products"
                  caption="Discover the versatility of Aquaphor<sup>&reg;</sup> Baby skin care products"
                  buttonText="GENERAL CARE SOLUTIONS"
                  buttonUrl="/baby/generalcare"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img5}
                  imgAlt="Aquaphor® Baby Diaper Rash Relief products"
                  caption="Recommended for relief <br class='show-desktop' /> of mild to severe diaper rash"
                  buttonText="DIAPER RASH RELIEF"
                  buttonUrl="/baby/diaperrash"
                  imgStyles={{ height: 165 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img6}
                  imgAlt="Resources and Support for Baby care"
                  caption="Find resources to support your baby care recommendations"
                  buttonText="Browse Resources"
                  buttonUrl="/resources"
                  imgStyles={{ height: 125 }}
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default EczemaPage;
