import React from "react";
import { Container, Row, Col } from "react-grid-system";

const CategoryDetails = ({ headBgColor, headText, infoText, bodySpanText, bodyText, productInfoIcon, productInfoIconAlt, productInfoImg, productInfoImgAlt, imgHeader, imgCaption, className, headContent, children, ...props }) => {
  return (
    <div {...props}
      className="product-category-details">
      <h3 style={{ backgroundColor: headBgColor }} className="category-name">{headText}</h3>
      <h4 className="product-info" dangerouslySetInnerHTML={{ __html: infoText }} />
      <p className="box-text">{bodySpanText ? <span className="font-semi-bold">{bodySpanText}</span> : ''}<span dangerouslySetInnerHTML={{ __html: bodyText }} /> </p>
      {headContent && <div className="head-content">
        <div dangerouslySetInnerHTML={{ __html: headContent }}>
        </div>
      </div>
      }
      <div className="product-info-inner">
        <Container fluid>
          <Row align="center" justify="center" direction="row">
            <Col sm={12} md={8} className="no-px left-col" order={{ xs: 2, md: 1 }}>

              {productInfoIcon ?
                <Row align="center" justify="center" direction="row" className="border-col no-mx">
                  <Col sm={12} md={3}>
                    <img src={productInfoIcon} className="product-info-icon" alt={productInfoIconAlt ? productInfoIconAlt : ""} />
                  </Col>
                  <Col sm={12} md={9}>
                    {children}
                  </Col>
                </Row>
                : <Row align="center" justify="center" direction="row" className="border-col no-mx">
                  <Col sm={12}>
                    {children}
                  </Col>
                </Row>}
            </Col>
            <Col sm={12} md={4} className="no-px right-col" order={{ xs: 1, md: 2 }}>
              {imgHeader ? <p className="img-header" dangerouslySetInnerHTML={{ __html: imgHeader }} /> : ""}
              <img src={productInfoImg} alt={productInfoImgAlt} className="right-col-img" />
              {imgHeader ? <div className="img-caption" dangerouslySetInnerHTML={{ __html: imgCaption }} /> : ""}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
};

export default CategoryDetails;
